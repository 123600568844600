export default class Menu {
  init() {
    const buttonxx = document.getElementById("mobile-menu-btn");
    const desktopMenu = document.getElementById("menu");
    const mobileMenu = document.getElementById("mobile-menu");
    const desktopMenuBtn = document.getElementById("desktop-menu-btn-container");
    const mobileMenuBtn = document.getElementById("mobile-menu-btn-container");
      if (buttonxx){
        buttonxx.addEventListener("click", function () {
        const isActive = mobileMenu.classList.toggle("active");
        if (isActive) {
          document.body.style.overflowY = "hidden";
          mobileMenuBtn.appendChild(buttonxx);
          desktopMenu.style.display = "none";
        }
        else {
          document.body.style.overflowY = "visible";
          desktopMenu.style.display = "block";
          desktopMenuBtn.appendChild(buttonxx);
        }
        buttonxx.textContent = isActive ? "Close" : "Menu";
        });
      }      
  }
}
