export default class Filter {
  init() {
    const mobileBtn = document.getElementById('mobile-btn-container');
    const dropdownOptions = document.querySelectorAll(".dropdown-options");
    if (mobileBtn) {
    mobileBtn.addEventListener("click", (event) => {
        mobileBtn.classList.toggle("active");
      }
    ) };
    dropdownOptions.forEach((dropdownOption) => {
      document.querySelectorAll(".mobile-btn").forEach((btn) => {
        mobileBtn.classList.toggle("active");
        btn.addEventListener("click", (event) => {
          dropdownOption.classList.toggle("active");
        });
      });
    });
  }
}
